import axios from 'axios';

class EmployeesAPI {
    getEmplyees = (offset: number, limit: number, filters: any) => {
        return axios.get(`/employee/list?offset=${offset}&limit=${limit}`, {
            params: filters,
        });
    };

    getConfirmedEmployees = (offset: number, limit: number) => {
        return axios.get(`/employee/confirmed?offset=${offset}&limit=${limit}`);
    };

    setStatus = (id: string, status: string) => {
        return axios.post(`/employee/${id}/change-status`, {
            status,
        });
    };
}

const employeesAPI = new EmployeesAPI();
export default employeesAPI;
