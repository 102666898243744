import React, { useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';

import { useStores } from '../../hooks/useStores';

import Loader from '../../components/Loader/Loader';
import ComplexCarousel from '../../components/Complex/ComplexCarousel';
import RequestError from '../../components/RequestError/RequestError';
import ComplexFeatures from '../../components/Complex/ComplexFeatures';
import ComplexAdvantages from '../../components/Complex/ComplexAdvantages';
import NewsCarousel from '../../components/NewsCarousel/NewsCarousel';
import StockCarousel from '../../components/StockCarousel/StockCarousel';
import ComplexObjects from '../../components/Complex/ComplexObjects';
import ComplexBanks from '../../components/Complex/ComplexBanks';
import TextButton from '../../components/UI/TextButton';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import Download2Icon from '../../components/UI/Download2Icon';

const Complex = observer(() => {
    const { ComplexStore: store, NewsStore, StockStore } = useStores();
    const match: any = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();

    const handleBackToComplexes = () => history.push('/residential-complexes');

    const renderContent = (): any => {
        if (store.complex?.success) {
            const { data } = store.complex;

            return (
                <>
                    <Box mb={{ xxs: 2, sm: 3 }} display="flex" justifyContent="flex-end">
                        <TextButton
                            className={classes.goBackButton}
                            color="primary"
                            startIcon={<ArrowBackOutlinedIcon />}
                            onClick={handleBackToComplexes}
                        >
                            К списку ЖК
                        </TextButton>

                        {data.genplan.genplan_image && (
                            <Box mr={{ xxs: 2, md: 4 }} display="inline-block">
                                <a href={data.genplan.genplan_image} target="_blank" rel="noopener noreferrer">
                                    <TextButton>
                                        <span className={classes.presentationButtonText}>Генплан</span>
                                    </TextButton>
                                </a>
                            </Box>
                        )}

                        {data.presentationUrl && (
                            <Box mr={{ xxs: 2, md: 4 }} display="inline-block">
                                <a href={data.presentationUrl} target="_blank" rel="noopener noreferrer">
                                    <TextButton startIcon={<Download2Icon />}>
                                        <span className={classes.presentationButtonText}>Презентация</span>
                                    </TextButton>
                                </a>
                            </Box>
                        )}

                        <Link to={`/apartments?&complex=${data.id}`}>
                            <TextButton startIcon={<MapOutlinedIcon />}>
                                <span className={classes.mapButtonText}>На карте</span>
                            </TextButton>
                        </Link>
                    </Box>
                    <Typography variant="h2">{data.name}</Typography>

                    <Typography>
                        <LocationOnOutlinedIcon fontSize="inherit" color="action" style={{ top: '2px', position: 'relative' }} />{' '}
                        {data.address}
                    </Typography>

                    <ComplexCarousel data={data} />

                    <Box mb={4}>
                        <Typography variant="h2">Объекты</Typography>
                        <Typography variant="h4">{data.priceFormat}</Typography>
                    </Box>
                    <ComplexObjects complex={data} />
                    <ComplexFeatures data={data.factoids} />
                    <NewsCarousel data={NewsStore.newsList} isLoading={NewsStore.loading} />
                    <StockCarousel data={StockStore.stocks} isLoading={StockStore.loading} />
                    <ComplexBanks data={data.banks} />
                    <ComplexAdvantages data={data.advantages} />
                </>
            );
        } else if (!store.complex?.success) {
            return <RequestError />;
        }

        return <div />;
    };

    useEffect(() => {
        if (match.params?.complexId) {
            store.getComplex(match.params.complexId);
        }
    }, [store, match.params]);

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const page = parseInt(parsed.page as string) - 1 || 0;
        if (match.params?.complexId) {
            NewsStore.getComplexNews(match.params.complexId, page, 20);
        }
        return () => {
            NewsStore.resetNews();
        };
    }, [location.search, NewsStore, match.params]);

    useEffect(() => {
        const parsed = queryString.parse(location.search);
        const page = parseInt(parsed.page as string) - 1 || 0;

        if (match.params?.complexId) {
            StockStore.getComplexStocks(match.params.complexId, page, 20);
        }

        return () => {
            StockStore.resetStocks();
        };
    }, [location.search, StockStore, match.params]);

    if (store.loading) {
        return <Loader />;
    }

    return (
        <Box py={5} className="complex-page">
            <Container maxWidth="xl">{renderContent()}</Container>
        </Box>
    );
});

const useStyles = makeStyles(theme => ({
    goBackButton: {
        marginRight: "auto"
    },
    presentationButtonText: {
        '@media (max-width: 424px)': {
            display: "none",
        },
    },
    mapButtonText: {
        '@media (max-width: 424px)': {
            display: "none",
        },
    }
}));

export default Complex;
