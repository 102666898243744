import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { Grid, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { useStores } from '../../hooks/useStores';
import { useMenu } from '../../hooks/useMenu';

import TextButton from '../UI/TextButton';

const HeaderMenu = observer(() => {
    const { anchorEl, handleOpen, handleClose } = useMenu();
    const { ProfileStore } = useStores();

    const classes = useStyles();

    return (
        <>
            <TextButton
                className={classes.menuButton}
                endIcon={<KeyboardArrowDownIcon />}
                aria-owns={!!anchorEl ? 'header-menu' : undefined}
                aria-haspopup="true"
                onClick={handleOpen}
            >
                Ещё
            </TextButton>

            <Popover
                id="header-menu"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                classes={{ paper: classes.menuPaper }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
            >
                <Grid container direction="column" spacing={1}>
                    {/* <Grid item>
                        <NavLink to="/calculator" activeClassName="selected" className={classes.menuLink}>
                            Ипотечный калькулятор
                        </NavLink>
                    </Grid> */}
                    <Grid item>
                        <NavLink to="/feedback" activeClassName="selected" className={classes.menuLink}>
                            Контакты
                        </NavLink>
                    </Grid>

                    {/*<Grid item>*/}
                    {/*    <NavLink to="/documents" activeClassName="selected" className={classes.menuLink}>*/}
                    {/*        Отчеты и документы*/}
                    {/*    </NavLink>*/}
                    {/*</Grid>*/}

                    {ProfileStore.userRole === 'director' && !ProfileStore.isSelfEmployed && (
                        <Grid item>
                            <NavLink to="/employees" activeClassName="selected" className={classes.menuLink}>
                                Сотрудники
                            </NavLink>
                        </Grid>
                    )}
                </Grid>
            </Popover>
        </>
    );
});

const useStyles = makeStyles(theme => ({
    menuLink: {
        ...theme.typography.subtitle1,
        //@ts-ignore
        color: theme.palette.material.blackMedium,
        display: 'inline-block',
        '&:hover': {
            color: theme.palette.text.primary,
        },
        '&.selected': {
            color: theme.palette.primary.main,
            borderBottom: `3px solid ${theme.palette.primary.main}`,
            borderRadius: 2,
        },
    },
    menuButton: {
        display: 'flex',
        //@ts-ignore
        color: theme.palette.material.blackMedium,
        '&:hover': {
            color: theme.palette.text.primary,
            cursor: 'pointer',
        },
    },
    menuPaper: {
        //@ts-ignore
        ...theme.overrides.MuiPaper.elevation3,
        borderRadius: 16,
        top: '72px !important',
        padding: theme.spacing(2),
    },
}));

export default HeaderMenu;
