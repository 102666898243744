import axios from 'axios';
import { ISignIn, ISignUp, IResetPassReq, IResetPassCheck, IResetPassConfirm, IAutoLoginData } from '../types/AuthTypes';

class AuthAPI {
    login = (data: ISignIn) => {
        return axios.post('/auth/login', data);
    };

    logout = () => {
        return axios.post('/auth/logout');
    };

    register = (data: ISignUp) => {
        const sendData = JSON.parse(JSON.stringify(data));
        return axios.post('/auth/register', sendData);
    };

    resetPasswordRequest = (data: IResetPassReq) => {
        return axios.post('/reset-password/request', data);
    };

    resetPasswordCheck = (data: IResetPassCheck) => {
        return axios.post('/reset-password/checking', data);
    };

    resetPasswordConfirm = (data: IResetPassConfirm) => {
        return axios.post('/reset-password/confirm', data);
    };

    autoLogin = (data: IAutoLoginData) => {
        return axios.post('/auth/autologin', data);
    };
}

const authAPI = new AuthAPI();
export default authAPI;
