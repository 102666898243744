export interface IEmployee {
    id: string;
    name: string;
    phone: number;
    email: string | null;
    date_birth: string | null;
    birthday: string | null;
    status: string;
    role: string;
}

export enum EmployeeStatusFiltersValues {
    confirmed = 'confirmed',
    new = 'new',
    rejected = 'rejected',
}

export enum EmployeeRoleFiltersValues {
    realtor = 'realtor',
    director = 'director',
    selfEmployed = 'self_employed',
}

export type TEmployeeFilterGroup = 'status' | 'role';

export interface IEmployeeFilterItem {
    id: string;
    title: string;
}

export interface IEmployeeFilter {
    title: string;
    group: TEmployeeFilterGroup;
    items: IEmployeeFilterItem[];
}