import { observable, action } from 'mobx';
import API from '../api';
import { IEmployee } from '../types/EmployeesTypes';
import { IPagination } from '../types/CommonTypes';

export class EmployeesStore {
    @observable loading = false;

    @observable employees: IEmployee[] = [];
    @observable employeesPagination: IPagination | null = null;
    @observable paginationPage = 0;
    @observable filters: { status: string[], role: string[] } = {
        status: [],
        role: []
    };

    @action
    getEmployees = (offset: number, limit: number) => {
        this.loading = true;

        API.employees
            .getEmplyees(offset * limit, limit, this.filters)
            .then(response => {
                if (response?.data?.success && response.data.data?.items) {
                    this.employees = [...this.employees, ...response.data.data.items];
                    this.employeesPagination = response.data.data.pagination;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    getConfirmedEmployees = (offset: number, limit: number) => {
        this.loading = true;

        API.employees
            .getConfirmedEmployees(offset, limit)
            .then(response => {
                if (response?.data?.success) {
                    this.employees = response.data.data;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    setStatus = (id: string, status: string) => {
        this.loading = true;
        API.employees
            .setStatus(id, status)
            .then(response => {
                if (response?.data?.data) {
                    const index = this.employees.findIndex((employee: any) => employee.id === id);

                    this.employees[index].role = response.data.data?.agency.role;
                    this.employees[index].status = response.data.data?.status;
                }
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    resetEmployees = () => {
        this.employees = [];
    };

    @action
    setPaginationPage = (page: number) => {
        this.paginationPage = page;
    };

    @action
    setFilter = (group: 'status' | 'role', value: string) => {
        const index = this.filters[group].indexOf(value);

        if (index !== -1) {
            this.filters[group].splice(index, 1);
        } else {
            this.filters[group].push(value);
        }
    }
}
