import React from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import LockIcon from '@material-ui/icons/Lock';
import { makeStyles } from '@material-ui/core/styles';

const ApartmentTilesLegend = () => {
    const classes = useStyles();

    return (
        <Box mb={3} ml={3} maxWidth={1200} position="relative">
            <Divider className={clsx(classes.divider, classes.topDivider)} />

            <Grid container>
                <Grid item xs={12} sm={6} md={4} lg={3} className={classes.gridItem}>
                    <Box mr={0.5}>
                        <Typography variant="subtitle2">1К</Typography>
                    </Box>
                    <Box mr={0.5}>
                        <Typography variant="subtitle2">2К</Typography>
                    </Box>
                    <Box mr={1}>
                        <Typography variant="subtitle2">3К</Typography>
                    </Box>
                    <Box mr={1}>
                        <Typography variant="subtitle2">С</Typography>
                    </Box>
                    <Typography variant="body2">Квартиры по комнатности</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={2} className={classes.gridItem}>
                    <Box mr={1}>
                        <Typography variant="subtitle2">Оф</Typography>
                    </Box>
                    <Typography variant="body2">Офисное помещение</Typography>
                </Grid>

                <Grid item xs={6} sm={3} lg={2} className={classes.gridItem}>
                    <Box mr={1}>
                        <Typography variant="subtitle2">Кл</Typography>
                    </Box>
                    <Typography variant="body2">Кладовая</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={2} className={classes.gridItem}>
                    <Box mr={1}>
                        <Typography variant="subtitle2">ГП</Typography>
                    </Box>
                    <Typography variant="body2">Парковки</Typography>
                </Grid>

                <Grid item xs={6} sm={3} lg={1} className={classes.gridItem}>
                    <Box mr={1}>
                        <Typography variant="subtitle2">П</Typography>
                    </Box>
                    <Typography variant="body2">Паркинг</Typography>
                </Grid>

                <Grid item xs={6} sm={3} lg={1} className={classes.gridItem}>
                    <Box mr={1}>
                        <Typography variant="subtitle2">Ск</Typography>
                    </Box>
                    <Typography variant="body2">Склад</Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={3} className={classes.gridItem}>
                    <Box mr={1}>
                        <Typography variant="subtitle2">Св</Typography>
                    </Box>
                    <Typography variant="body2">Помещение свободного назначения</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={2} className={classes.gridItem}>
                    <Box mr={1}>
                        <Typography variant="subtitle2">ТП</Typography>
                    </Box>
                    <Typography variant="body2">Торговое помещение</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={2} className={classes.gridItem}>
                    <Box mr={1}>
                        <LockIcon color="disabled" fontSize="small" />
                    </Box>
                    <Typography variant="body2">Забронировано</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={2} className={classes.gridItem}>
                    <Box className={classes.emptyTile} />
                    <Typography variant="body2">Продано</Typography>
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.bottomDivider)} />
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    emptyTile: {
        width: 16,
        height: 16,
        //@ts-ignore
        background: theme.palette.primary.A50,
        marginRight: theme.spacing(2),
        borderRadius: 4,
    },
    gridItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        paddingBottom: 10,
    },
    divider: {
        //@ts-ignore
        background: theme.palette.primary.A100,
        margin: '0px -100%',
        padding: '0px 100%',
    },
    topDivider: {
        marginBottom: 8,
    },
    bottomDivider: {
        meginTop: 8,
    },
}));

export default ApartmentTilesLegend;
