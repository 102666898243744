import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
    Container,
    Box,
    Typography,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Divider,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

import { useStores } from '../../hooks/useStores';
import TextButton from '../../components/UI/TextButton';
import { observer } from 'mobx-react-lite';
import {
    EmployeeRoleFiltersValues,
    EmployeeStatusFiltersValues,
    IEmployee,
    IEmployeeFilterItem,
} from '../../types/EmployeesTypes';
import Loader from '../../components/Loader/Loader';
import EmployeeFilter from '../../components/Employees/EmployeeFilter';

const statusFilters: IEmployeeFilterItem[] = [
    {
        id: EmployeeStatusFiltersValues.confirmed,
        title: 'Действующие'
    },
    {
        id: EmployeeStatusFiltersValues.new,
        title: 'Неподтвержденные'
    },
    {
        id: EmployeeStatusFiltersValues.rejected,
        title: 'Отклонённые'
    }
]

const rolesFilters: IEmployeeFilterItem[] = [
    {
        id: EmployeeRoleFiltersValues.realtor,
        title: 'Риелтор'
    },
    {
        id: EmployeeRoleFiltersValues.director,
        title: 'Руководитель'
    }
]

const Employees = observer(() => {
    const { EmployeesStore: store } = useStores();
    const location = useLocation();
    const theme = useTheme();
    const isSm = useMediaQuery(theme.breakpoints.down('sm'));

    const [disabledButtons, setDisabledButtons] = useState<string[]>([])
    //очередь таймаутов
    const [timerQueue, setTimerQueue] = useState<any[]>([]);

    // Effects
    useEffect(() => {
        store.getEmployees(store.paginationPage, 20);
    }, [store, location, store.paginationPage]);

    useEffect(() => {
        return () => {
            store.resetEmployees();
        };
    }, [store]);

    useEffect(() => {
        return () => timerQueue.forEach(({ timerId }) => {
            clearTimeout(timerId)});
    },[])

    // Handlers
    const fetchMoreEmployees = () => {
        store.setPaginationPage(store.paginationPage + 1);
    };

    const handleClickButton = (id: string, status: string) => {
        const timerId = setTimeout(() => {
            setDisabledButtons(buttons => buttons.filter((button) => button !== id))
            setTimerQueue((queue) => queue.filter((el) => el !== id));
        }, 60000)

        setTimerQueue((queue) =>
            queue.concat(timerId)
        );

        setDisabledButtons([...disabledButtons, id])

        store.setStatus(id, status)
    }

    // Renders
    const renderLoader = () => {
        return (
            <Box
                position="relative"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                width="100%"
                minHeight="120px"
            >
                <Loader isAbsolute />
            </Box>
        );
    };

    const renderButtons = (item: IEmployee) => {
        if (item.role !== 'rejected' && item.role !== 'director') {
            return (
                <Box display={{ xxs: 'block', lg: 'flex' }} mx={-1.5} my={-1} justifyContent="flex-end">
                    <Box display={{ xxs: 'block', lg: 'inline-flex' }} px={1.5} my={1}>
                        <TextButton disabled={disabledButtons.includes(item.id)} onClick={() => handleClickButton(item.id, 'rejected')}>Заблокировать</TextButton>
                    </Box>
                    {item.role === 'unconfirmed' && (
                        <Box
                            display={{ xxs: 'block', lg: 'inline-flex' }}
                            px={1.5}
                            my={1}
                        >
                            <TextButton disabled={disabledButtons.includes(item.id)} onClick={() => handleClickButton(item.id, 'realtor')}>Подтвердить</TextButton>
                        </Box>
                    )}
                </Box>
            );
        }

        return null;
    };

    const renderContent = () => {
        if (!isSm) {
            return (
                <InfiniteScroll
                    dataLength={store.employees.length}
                    next={fetchMoreEmployees}
                    hasMore={
                        store.employees.length > 0 && store.employeesPagination?.totalCount !== store.employeesPagination?.currentOffset
                    }
                    loader={renderLoader()}
                >
                    {store.loading ? <Loader isAbsolute /> : null}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">
                                        ФИО
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">
                                        Контакты
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">
                                        Дата рождения
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <EmployeeFilter title="Статус" group="status" items={statusFilters} />
                                </TableCell>
                                <TableCell>
                                    <EmployeeFilter title="Роль" group="role" items={rolesFilters} />
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {store.employees.length > 0 && (
                              <>
                                  {store.employees?.map((item: IEmployee) => (
                                      <TableRow key={item.id}>
                                          <TableCell>
                                              <Box maxWidth="235px">
                                                  <Typography variant="body2">{item.name}</Typography>
                                              </Box>
                                          </TableCell>
                                          <TableCell>
                                              <Typography variant="body2" noWrap>
                                                  {item.email}
                                              </Typography>
                                              <Typography variant="body2" noWrap>
                                                  {item.phone}
                                              </Typography>
                                          </TableCell>
                                          <TableCell>
                                              <Typography variant="body2">
                                                  {item.date_birth ? moment(item.date_birth).format('DD MMM YYYY г.') : '-'}
                                              </Typography>
                                          </TableCell>
                                          <TableCell>
                                              <Typography variant="body2" style={{ color: getStatusColor(item.status) }}>
                                                  {item.status}
                                              </Typography>
                                          </TableCell>
                                          <TableCell>
                                              <Typography variant="body2" style={{ color: getStatusColor(item.role) }}>
                                                  {item.role}
                                              </Typography>
                                          </TableCell>
                                          <TableCell>{renderButtons(item)}</TableCell>
                                      </TableRow>
                                  ))}
                              </>
                            )}
                            {store.employees.length === 0 && (
                                <TableRow>
                                    <TableCell>По вашему запросу ничего не найдено</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </InfiniteScroll>
            );
        }

        return (
            <Box position="relative" display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                <Box width="100%">
                    {!store.employees.length && store.loading ? renderLoader() : null}
                    <InfiniteScroll
                        dataLength={store.employees.length}
                        next={fetchMoreEmployees}
                        hasMore={
                            store.employees.length > 0 && store.employeesPagination?.totalCount !== store.employeesPagination?.currentOffset
                        }
                        loader={renderLoader()}
                    >
                        {store.employees?.map((item: any, index: number) => (
                            <Box key={item.id}>
                                {index > 0 && <Divider />}
                                <Box display="flex" alignItems="center" mx={-1} mt={2}>
                                    <Box display="inline-flex" p={1}>
                                        <Typography variant="body2">{item.name}</Typography>
                                    </Box>
                                </Box>
                                <Box my={2}>
                                    <Typography variant="body2" color="textSecondary">
                                        E-mail
                                    </Typography>
                                    <Typography variant="body2" noWrap>
                                        {item.email}
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Typography variant="body2" color="textSecondary">
                                        Телефон
                                    </Typography>

                                    <Typography variant="body2" noWrap>
                                        {item.phone}
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Typography variant="body2" color="textSecondary">
                                        Дата рождения
                                    </Typography>
                                    <Typography variant="body2" noWrap>
                                        {item.date_birth ? moment(item.date_birth).format('DD MMM YYYY г.') : '-'}
                                    </Typography>
                                </Box>
                                <Box my={2}>
                                    <Typography variant="body2" color="textSecondary">
                                        Статус
                                    </Typography>
                                    <Typography variant="body2" style={{ color: getStatusColor(item.role) }}>
                                        {item.status}
                                    </Typography>
                                </Box>
                                <Box my={2}>{renderButtons(item)}</Box>
                            </Box>
                        ))}
                    </InfiniteScroll>
                </Box>
            </Box>
        );
    };

    return (
        <Container maxWidth="xl">
            <Box mt={{ xxs: 4, md: 6 }} mb={{ xxs: 7, md: 9 }}>
                <Box mb={{ xxs: 2, md: 4 }}>
                    <Typography variant="h2">Сотрудники</Typography>
                </Box>

                {renderContent()}
            </Box>
        </Container>
    );
});

const getStatusColor = (role: string) => {
    switch (role) {
        case 'unconfirmed':
            return '#37C49B';

        case 'rejected':
            return '#F8133B';

        default:
            return 'inherit';
    }
};

export default Employees;
