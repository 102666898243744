import React from 'react';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Popover,
    Typography,
} from '@material-ui/core';
import { useMenu } from '../../hooks/useMenu';
import TextButton from '../UI/TextButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IEmployeeFilter } from '../../types/EmployeesTypes';
import { useStores } from '../../hooks/useStores';

const EmployeeFilter = (props: IEmployeeFilter) => {
    const { EmployeesStore: store } = useStores();
    const { anchorEl, handleOpen, handleClose } = useMenu();

    const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
        store.setFilter(props.group, e.currentTarget.name);
        store.setPaginationPage(0);
        store.resetEmployees();
        store.getEmployees(store.paginationPage, 20);
    }

    return (
        <>
            <TextButton
                endIcon={<KeyboardArrowDownIcon />}
                aria-owns={!!anchorEl ? 'employee-status-filter' : undefined}
                aria-haspopup="true"
                onClick={handleOpen}
            >
                <Typography variant="body2" color="textSecondary">
                    {props.title}
                </Typography>
            </TextButton>

            <Popover
                id="employee-status-filter"
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                disableRestoreFocus
            >
                <Grid container direction="column" spacing={0}>
                    {props.items.map(item => (
                        <Grid item key={item.id}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={item.id}
                                        checked={store.filters[props.group].includes(item.id)}
                                        onChange={handleCheckbox} />
                                }
                                label={<Typography variant="body2">{item.title}</Typography>}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </>
    );
};

export default EmployeeFilter;
