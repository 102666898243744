import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Box, Button, makeStyles, Paper, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';

import { IComplexesData } from '../../types/ComplexTypes';

import ComplexObjects from '../Complex/ComplexObjects';
import TextButton from '../UI/TextButton';
import Download2Icon from '../UI/Download2Icon';

const ComplexesItem = observer(({ complex }: { complex: IComplexesData }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMD = useMediaQuery(theme.breakpoints.down('md'));

    const [showButton, setShowButton] = useState(false);

    return (
        <Box
            key={complex.id}
            mx={{ xxs: 2, lg: 1 }}
            my={5}
            onMouseEnter={() => setShowButton(true)}
            onMouseLeave={() => setShowButton(false)}
        >
            <Paper variant="outlined" className={classes.card}>
                <Box className={classes.container}>
                    <img src={complex.image} alt="" className={classes.image} />

                    <Box pt={3} pb={2} pl={3} pr={2} display="flex" flexDirection="column" width="100%">
                        <Box mb={1} display="flex" alignItems="center">
                            <Box  alignSelf="flex-start">
                                <Typography variant="h3" className="text-nowrap">
                                    {complex.name}
                                </Typography>
                            </Box>
                            <Box marginLeft="auto">
                                <Typography className="text-nowrap" variant="body2" color="textSecondary">
                                    {!!complex.completionDate && `Срок сдачи: ${complex.completionDate}`}
                                </Typography>
                            </Box>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="body2" className="text-nowrap">
                                <LocationOnOutlinedIcon fontSize="inherit" color="action" style={{ top: '2px', position: 'relative' }} />
                                {complex.address}
                            </Typography>
                        </Box>
                        <Box mb={1}>
                            <Typography variant="h4">Объекты • {complex.priceFormat}&nbsp;</Typography>
                        </Box>
                        <Box>
                            <ComplexObjects complex={complex} />
                        </Box>
                        <Box className={classes.footer}>
                            <Box alignSelf="flex-end" mt={4} className={classes.completion}>&nbsp;</Box>
                            {isMD ? (
                                <Box className={classes.footerButton}
                                     display="flex"
                                     alignItems="center"
                                     justifyContent="space-around"
                                     flexWrap="wrap"
                                >
                                    {complex.genplan.genplan_image && (
                                        <Box mr={2} display="inline-block" mb={{ xxs: 1, sm: 0 }}>
                                            <a href={complex.genplan.genplan_image} target="_blank" rel="noopener noreferrer">
                                                <TextButton>Генплан</TextButton>
                                            </a>
                                        </Box>
                                    )}
                                    {complex.presentationUrl && (
                                        <Box mr={2} display="inline-block" mb={{ xxs: 1, sm: 0 }}>
                                            <a href={complex.presentationUrl} target="_blank" rel="noopener noreferrer">
                                                <TextButton startIcon={<Download2Icon />}>Презентация</TextButton>
                                            </a>
                                        </Box>
                                    )}
                                    <Box mr={2} display="inline-block" mb={{ xxs: 1, sm: 0 }}>
                                        <Link to={`/apartments?&complex=${complex.id}`}>
                                            <TextButton startIcon={<MapOutlinedIcon />}>На карте</TextButton>
                                        </Link>
                                    </Box>
                                    <Link to={`/residential-complexes/${complex.id}`}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            classes={{ root: classes.buttonRoot }}
                                            endIcon={<ChevronRightIcon />}
                                        >
                                            Перейти к ЖК
                                        </Button>
                                    </Link>
                                </Box>
                            ) : (
                                showButton && (
                                    <Box alignSelf="flex-end">
                                        {complex.genplan.genplan_image && (
                                            <Box mr={2} display="inline-block" mb={{ xxs: 1, sm: 0 }}>
                                                <a href={complex.genplan.genplan_image} target="_blank" rel="noopener noreferrer">
                                                    <TextButton>Генплан</TextButton>
                                                </a>
                                            </Box>
                                        )}
                                        {complex.presentationUrl && (
                                            <Box mr={2} display="inline-block" mb={{ xxs: 1, sm: 0 }}>
                                                <a href={complex.presentationUrl} target="_blank" rel="noopener noreferrer">
                                                    <TextButton startIcon={<Download2Icon />}>Презентация</TextButton>
                                                </a>
                                            </Box>
                                        )}
                                        <Box mr={2} display="inline-block">
                                            <Link to={`/apartments?&complex=${complex.id}`}>
                                            <TextButton startIcon={<MapOutlinedIcon />}>На карте</TextButton>
                                            </Link>
                                        </Box>
                                        <Link to={`/residential-complexes/${complex.id}`}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                classes={{ root: classes.buttonRoot }}
                                                endIcon={<ChevronRightIcon />}
                                            >
                                                Перейти к ЖК
                                            </Button>
                                        </Link>
                                    </Box>
                                )
                            )}
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
});

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        minHeight: 354,

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    image: {
        maxWidth: 344,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'none',
            maxHeight: 344,
        },
    },
    card: {
        //@ts-ignore
        borderColor: theme.palette.primary.A50,
        '&:hover': {
            //@ts-ignore
            ...theme.overrides.MuiPaper.elevation3,
        },
    },
    footer: {
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    completion: {
        [theme.breakpoints.down('sm')]: {
            order: 2,
            marginTop: theme.spacing(2),
        },
    },
    footerButton: {
        alignSelf: 'flex-end',
        order: 1,
        marginTop: theme.spacing(2),
    },
    buttonRoot: {
        borderRadius: 10,
    },
}));

export default ComplexesItem;
